<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Virtual Cards Transactions</h2>
      </div>
      <div class="col-span-1 flex gap-1">
        <select
          name=""
          id=""
          class="border border-solid border-blue-200 rounded-sm placeholder-blue-300 outline-none py-3 text-13 text-gray-600 w-[50px] px-1"
          v-model="virtualCards.selectFilter"
        >
          <option value="">No Filter</option>
          <option value="status">by status</option>
          <option value="paymentStatus">By PaymentStatus</option>
          <option value="category">By Category</option>
          <option value="type">By Type</option>
          <option value="currency">By Currency</option>
          <option value="referenceId">By Reference</option>
          <option value="userId">By userId</option>
        </select>

        <search-form
          v-model="virtualCards.queryValue"
          @submit="(event) => $refs.table.loadAjaxData()"
          v-if="virtualCards.selectFilter"
        />
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :url="`${this.$baseurl}/cards/admin/transactions`"
        :ajax="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="virtualCards.data"
        :columns="virtualCards.columns"
        :selectFilter="virtualCards.selectFilter"
        :queryValue="virtualCards.queryValue"
        :dont_sort="virtualCards.dont_sort"
        :sorted="true"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      virtualCards: this.$options.resource([], {
        selectFilter: '',
        queryValue: '',
        dont_sort: ['user', 'email'],
        columns: [
          {
            name: 'user',
            th: 'User',
            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.firstName} ${
                transaction?.user?.lastName || ''
              }`;
            },
          },
          {
            name: 'cardId',
            th: 'Virtual Card Id',
            render: (card) => {
              return `${card?.cardId || 'N/A'}`;
            },
          },
          {
            name: 'email',
            th: 'EMAIL',

            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.email}`;
            },
          },

          {
            name: 'type',
            th: 'cardType',
          },

          {
            name: 'amount',
            th: 'amount',
          },

          {
            name: 'category',
            th: 'category',
          },
          {
            name: 'currency',
            th: 'Currency',
          },

          {
            name: 'status',
            th: 'Status',
            render: (transaction, status) => {
              switch (status) {
                case 'SUCCESS':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                                ${status}
                              </div>`;
                default:
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                                ${status}
                            </div>`;
              }
            },
          },

          {
            name: 'paymentStatus',
            th: 'paymentStatus',
            render: (transaction, status) => {
              switch (status) {
                case 'SUCCESS':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                                ${status}
                              </div>`;
                default:
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                                ${status}
                            </div>`;
              }
            },
          },

          {
            name: 'created_at',
            th: 'Date Created',
            render: (transaction) => {
              return moment(transaction?.user?.createdAt).format('MMM Do YYYY');
            },
          },
        ],
      }),
    };
  },
  methods: {
    goToUser({ user }) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user?.userId,
        },
      });
    },
  },
  watch: {
    // Watch for changes in selectFilter
    'virtualCards.selectFilter'(newValue) {
      if (!newValue) {
        this.virtualCards.queryValue = ''; // Reset queryValue if no filter is selected
      }
    },
  },
};
</script>
